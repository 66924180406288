import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'
import { useGetPopularRoutesMenuItemsQuery } from '@redux/features/homePage/homePage.api'
import type { BlockWithMenuType } from '@redux/features/homePage/types/blockWithMenu'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import Loader from '@base/loader/Loader'
import HeaderTabs from '@components/headerTabs/HeaderTabs'
import ListRoutes from '@components/mainPageBlocks/PopularRoutes/listRoutes/ListRoutes'
import { getTabOptions } from '@components/mainPageBlocks/PopularRoutes/utils/getTabOptions'

type Props = {
  data: ExtendBlocksType
}

const PopularRoutes: FC<Props> = ({ data }) => {
  const { menu_reference } = data as BlockWithMenuType
  const { data: popularRoutes, isLoading } = useGetPopularRoutesMenuItemsQuery(menu_reference, {
    skip: !menu_reference,
  })
  const tabOptions = useMemo(() => getTabOptions(popularRoutes), [popularRoutes])
  const [activeTab, setActiveTab] = useState(tabOptions[0]?.key)
  const loading = isLoading || !activeTab

  useEffect(() => {
    tabOptions[0]?.key && setActiveTab(tabOptions[0]?.key)
  }, [tabOptions])

  return (
    <Container data-block={BlocksKeys.BLOCK_WITH_MENU}>
      <Loader absoluteCenter spinning={loading} />
      {!loading && (
        <>
          <HeaderTabs activeTabKey={activeTab} setActiveTab={setActiveTab} tabsOptions={tabOptions} />
          <ListRoutes activeTab={activeTab} popularRoutesList={popularRoutes} />
        </>
      )}
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  height: auto;
  position: relative;
`

export default memo(PopularRoutes)
